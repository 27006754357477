export default function TNCEN() {
  return (
    <>
      <div className="d-flex justify-content-center mt-4">
        <div className="font-30 fw-bold mb-5">
          TERM AND CONDITION KONKOIN MOBILE APP
        </div>
      </div>
      <div className="mt-4">
        <ul style={{ listStyleType: "decimal" }}>
          <li className="fw-bold mt-">
            Introduction
            <p className="fw-normal mt-2">
              Welcome to the KONKOIN mobile application ("App"). By using our
              App, you agree to comply with and be bound by the following terms
              and conditions ("Terms"). Please review these Terms carefully
              before using the App.
            </p>
          </li>
          <li className="fw-bold mt-4">
            Acceptance of Terms
            <p className="fw-normal mt-2">
              By downloading, installing, and/or using the App, you agree to be
              bound by these Terms. If you do not agree to these Terms, do not
              use the App.
            </p>
          </li>
          <li className="fw-bold mt-4">
            Eligibility
            <p className="fw-normal mt-2">
              You must be at least 15 years old to use the App. By using the
              App, you represent and warrant that you are at least 15 years old.
            </p>
          </li>
          <li className="fw-bold mt-4">
            Account Registration
            <ul className="fw-normal" style={{ listStyleType: "lower-alpha" }}>
              <li className="mt-2">
                To access certain features of the App, you may be required to
                register for an account ("Account").
              </li>
              <li className="mt-2">
                You agree to provide accurate, current, and complete information
                during the registration process and to update such information
                to keep it accurate, current, and complete.
              </li>
              <li className="mt-2">
                You are responsible for safeguarding your Account password and
                for all activities that occur under your Account.
              </li>
            </ul>
          </li>
          <li className="fw-bold mt-4">
            Use of the App
            <ul className="fw-normal" style={{ listStyleType: "lower-alpha" }}>
              <li className="mt-2">
                The App allows you to manage and trade cryptocurrencies.
              </li>
              <li className="mt-2">
                You agree to use the App only for lawful purposes and in
                accordance with these Terms.
              </li>
            </ul>
          </li>
          <li className="fw-bold mt-4">
            Privacy
            <p className="mt-2 fw-normal">
              Your use of the App is also governed by our Privacy Policy.
            </p>
          </li>
          <li className="fw-bold mt-4">
            Intellectual Property
            <ul className="fw-normal" style={{ listStyleType: "lower-alpha" }}>
              <li className="mt-2">
                All content on the App, including text, graphics, logos, and
                software, is the property of KONKOIN or its licensors and is
                protected by copyright and other intellectual property laws.
              </li>
              <li className="mt-2">
                You may not use, reproduce, distribute, or create derivative
                works based on this content without our express written
                permission.
              </li>
            </ul>
          </li>
          <li className="fw-bold mt-4">
            User Conduct
            <ul className="fw-normal" style={{ listStyleType: "lower-alpha" }}>
              <li className="mt-2">
                You agree not to use the App in any way that could damage,
                disable, overburden, or impair our servers or networks.
              </li>
              <li className="mt-2">
                You agree not to attempt to gain unauthorized access to any part
                of the App or any other systems or networks connected to the
                App.
              </li>
            </ul>
          </li>
          <li className="fw-bold mt-4">
            Transactions
            <ul className="fw-normal" style={{ listStyleType: "lower-alpha" }}>
              <li className="mt-2">
                All transactions conducted through the App are final and cannot
                be reversed.
              </li>
              <li className="mt-2">
                KONKOIN is not responsible for any loss or damage resulting from
                your transactions.
              </li>
            </ul>
          </li>
          <li className="fw-bold mt-4">
            Fees
            <p className="fw-normal mt-2">
              KONKOIN dapat mengenakan biaya untuk layanan tertentu yang KONKOIN
              may charge fees for certain services provided through the App. Any
              applicable fees will be disclosed to you prior to your use of the
              service.
            </p>
          </li>
          <li className="fw-bold mt-4">
            Disclaimers
            <ul className="fw-normal" style={{ listStyleType: "lower-alpha" }}>
              <li className="mt-2">
                The App is provided "as is" and "as available" without any
                warranties of any kind.
              </li>
              <li className="mt-2">
                KONKOIN does not guarantee that the App will be error-free or
                uninterrupted.
              </li>
            </ul>
          </li>
          <li className="fw-bold mt-4">
            Limitation of Liability
            <p className="fw-normal mt-2">
              To the fullest extent permitted by law, KONKOIN shall not be
              liable for any indirect, incidental, special, consequential, or
              punitive damages, or any loss of profits or revenues, whether
              incurred directly or indirectly, or any loss of data, use,
              goodwill, or other intangible losses, resulting from (i) your use
              of or inability to use the App; (ii) any unauthorized access to or
              use of our servers and/or any personal information stored therein;
              (iii) any bugs, viruses, trojan horses, or the like that may be
              transmitted to or through the App by any third party; or (iv) any
              errors or omissions in any content or for any loss or damage
              incurred as a result of your use of any content posted, emailed,
              transmitted, or otherwise made available through the App.
            </p>
          </li>
          <li className="fw-bold mt-4">
            Indemnification
            <p className="fw-normal mt-2">
              You agree to indemnify and hold KONKOIN, its affiliates, officers,
              agents, and employees harmless from any claim, demand, damages, or
              losses, including reasonable attorney's fees, arising out of or
              related to your use of the App or violation of these Terms.
            </p>
          </li>
          <li className="fw-bold mt-4">
            Governing Law
            <p className="fw-normal mt-2">
              These Terms and your use of the App shall be governed by and
              construed in accordance with the laws of the jurisdiction in which
              KONKOIN is based, without regard to its conflict of law
              principles.
            </p>
          </li>
          <li className="fw-bold mt-4">
            Change to Terms
            <p className="fw-normal mt-2">
              KONKOIN reserves the right to change these Terms at any time. Any
              changes will be effective immediately upon posting on the App.
              Your continued use of the App after any such changes constitutes
              your acceptance of the new Terms.
            </p>
          </li>
          <li className="fw-bold mt-4">
            Termination
            <p className="fw-normal mt-2">
              KONKOIN may terminate or suspend your access to the App at any
              time, without prior notice or liability, for any reason, including
              if you breach any of these Terms.
            </p>
          </li>
          <li className="fw-bold mt-4">
            Contact Us
            <p className="fw-normal mt-2">
              If you have any questions about these Terms, please contact us at{" "}
              <a href="mailto:konkoindigital@gmail.com">
                konkoindigital@gmail.com
              </a>
            </p>
          </li>
        </ul>
      </div>
    </>
  )
}
