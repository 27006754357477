import axios from "axios"
import { useEffect, useState } from "react"
import { baseurl } from "../config/http"

export default function News({ width }) {
  const [dataNews, setDataNews] = useState()
  const [header, setHeader] = useState("")
  useEffect(() => {
    axios
      .get(baseurl + "/news")
      .then(({ data }) => {
        setDataNews(data)
      })
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    axios
      .get(baseurl + "/news-header")
      .then(({ data }) => {
        console.log(data)
        setHeader(data.header)
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <>
      {dataNews && header ? (
        <div>
          <div
            className="container"
            style={{
              fontSize: width <= 540 ? 35 : width <= 760 ? 40 : 55,
              marginTop: width <= 540 ? -275 : -275,
              fontWeight: 400,
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-white">{header}</div>
            </div>
          </div>
          <div
            className="container"
            style={{ marginTop: width <= 540 ? 10 : 50 }}
          >
            <div
              className="scrollbar-none overflow-scroll"
              style={{ overflowY: "hidden", whiteSpace: "nowrap" }}
            >
              <div style={{ width: "auto" }}>
                {dataNews.map((e, i) => {
                  return (
                    <div
                      className="card border-0 me-3"
                      key={i}
                      style={{
                        width: 426,
                        display: "inline-block",
                        backgroundColor: "transparent",
                      }}
                    >
                      <div
                        className="bg-danger"
                        style={{ height: 240, borderRadius: "25px 25px 0 0" }}
                      >
                        <img
                          src={e.image}
                          className="img-fluid h-100 w-100"
                          style={{
                            objectFit: "cover",
                            borderRadius: "25px 25px 0 0 ",
                          }}
                          alt=""
                        />
                      </div>
                      <div
                        className="bg-koni-secondary text-white"
                        style={{ height: 240, borderRadius: "0 0 25px 25px " }}
                      >
                        <div
                          className="p-4 d-flex justify-content-between h-100"
                          style={{ flexDirection: "column" }}
                        >
                          <div className="">
                            <div
                              className="fw-bold font-16"
                              style={{ whiteSpace: "normal" }}
                            >
                              {e.headline}
                            </div>
                            <div className=" mt-2">
                              {new Date(e.updatedAt).toLocaleDateString()}
                            </div>
                          </div>
                          <div
                            className="d-flex justify-content-between align-items-end mt-3"
                            style={{ overflow: "hidden" }}
                          >
                            <div
                              className="font-14  news-ellipsis"
                              style={{ height: 100 }}
                            >
                              {e.detail}
                            </div>
                            <a
                              className=""
                              style={{
                                textDecoration: "underline",
                                color: "white",
                              }}
                              href={e.link}
                              target="__blank"
                            >
                              read
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  )
}
