import React, { Component } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
export default function CarouselBanner({ dataImage, width }) {
  //   function PauseOnHover() {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    borderRadius: 25,
  }

  return (
    <>
      {dataImage.length ? (
        <div
          className="text-white container"
          style={{ marginTop: width < 540 ? 0 : 100 }}
        >
          <div className="slider-container text-white">
            <Slider {...settings}>
              {dataImage.map((e, i) => {
                return (
                  <div
                    className="w-100"
                    style={{ zIndex: "-1 !important" }}
                    key={i}
                  >
                    <img
                      src={e.filename}
                      className="img-fluid  w-100 h-100"
                      style={{ objectFit: "cover", borderRadius: 25 }}
                      alt=""
                    />
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  )
}
