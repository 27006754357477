import React from "react"
import { useNavigate } from "react-router-dom"
import VerificationProcessImg from "../asset/success-upload-document-kyc.svg"
function KYCVerivicationProcess() {
  const navigate = useNavigate()
  return (
    <>
      <div
        className="text-roboto d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh", backgroundColor: "#FFFFFF" }}
      >
        <div className="d-flex">
          <div>
            <div className=" text-center">
              <img
                src={VerificationProcessImg}
                alt=""
                className="img-fluid mx-auto"
                style={{ width: 150, paddingLeft : "10%" }}
              />
              <p className="font-24 fw-bold mt-5">
                Your Delete Account is Success
              </p>
              <p className="font-14 mb-1">
                Your account has been deleted
              </p>
              
            </div>

            {/* button */}
            
          </div>
        </div>
      </div>
    </>
  )
}

export default KYCVerivicationProcess
