import { useNavigate } from "react-router"
import Navbar from "../component/navbar"

// Image

import imgBackgroung1 from "../asset/banner-moon-3.svg"
import imgPoster from "../asset/POSTER.png"
import imgPoster2 from "../asset/POSTER2.png"
import imgLogoKonkoin from "../asset/logoNavbar.svg"

import imgBackgroundEarth from "../asset/banner-earth.svg"
import Banner from "../component/banner"
import Countdown from "../component/countdown"
import Konkoin from "../component/konkoin"
import Roadmap from "../component/roadmap"
import Takeonomic from "../component/takeonomic"
import Bento from "../component/bento"
import { useEffect, useState } from "react"
import News from "../component/news"
import MediaPartner from "../component/media"
import BottomNavbar from "../component/bottom-navbar"
import vidBackgorund from "../asset/New3.mp4"
import ReactPlayer from "react-player"
import axios from "axios"
import { baseurl } from "../config/http"
import CarouselBanner from "../component/carousel-banner"
import CarouselVideo from "../component/carousel-video"

export default function LandingPage() {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)
  const [change, setChange] = useState(false)
  const updateDimensions = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions)
    return () => window.removeEventListener("resize", updateDimensions)
  }, [])
  const [dataImage, setDataImage] = useState()
  const [dataVideo, setDataVideo] = useState()
  useEffect(() => {
    axios
      .get(baseurl + "/image")
      .then(({ data }) => {
        let temp = data.filter((e) => e.category === "banner")
        if (temp.length) {
          let temp2 = temp.filter((e) => e.title.split("-")[0] === "slider")
          let temp3 = temp.filter((e) => e.title.split("-")[0] === "video")
          setDataImage(temp2)
          setDataVideo(temp3)
          // console.log(temp2)
        }
      })
      .catch((err) => console.log(err))
  }, [])

  console.log(dataVideo)

  const styleMobile = {
    backgroundImage: `linear-gradient(to bottom, rgba(37,37,37,0) 30% ,#080808 65%), linear-gradient( to top , rgba(37,37,37,0), rgba(37,37,37,0)90% ,#080808),url(${imgPoster})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% auto",
    backgroundPosition: "0px 50px",
    // height: "100%",
    marginTop: -30,
    width: "100%",
  }
  const styleWeb = {
    backgroundImage: `linear-gradient(to bottom, rgba(37,37,37,0) 30% ,#080808 100%),url(${imgPoster2})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    // height: "100%",
    marginTop: -30,
    width: "100%",
  }
  return (
    <>
      {dataImage ? (
        <div className="" id="home">
          {/* <div style={width <= 540 ? styleMobile : styleWeb}>
          <Navbar />
          <Banner width={width} />
          
          </div> */}
          <Navbar />
          <div>
            {/* <iframe
              src={require("../asset/audio-konkoin.mp3")}
              // allow="autoplay"
              autoPlay
              // style="display:none"
              id="iframeAudio"
            ></iframe> */}
          </div>
          <div>
            {/* <ReactPlayer
            className="react-player"
            url={require("../asset/New3.mp4")}
            style={{}}
            width="100%"
            height="100%"
            playing
            muted
            loop
          /> */}
            {/* <div style={{ position: "relative" }}>
              <video
                autoPlay
                loop
                muted
                controls
                width={"100%"}
                src={
                  dataImage
                    ? dataImage[0].filename
                    : require("../asset/New3.mp4")
                }
                // src="https://drive.google.com/file/d/1efwfbwQKGvcP7ydjkTG1cePQMJzEhGy-/preview"
                style={{ zIndex: -2, marginTop: 0 }}
              ></video>
            </div> */}
            {/* {width < 540 ? (
              ""
            ) : (
              <div
                className="d-flex justify-content-end"
                style={{ position: "relative", zIndex: 3, marginTop: -100 }}
              >
                <audio
                  style={{ width: 150, opacity: "50%" }}
                  loop
                  autoPlay
                  controls
                  type="audio/mpeg"
                  src={require("../asset/audio-konkoin.mp3")}
                ></audio>
              </div>
            )} */}
            {/* 
            <div
              className=""
              style={{ zIndex: 3, marginTop: width < 540 ? "5%" : "-25%" }}
            >
              {width < 540 ? (
                <div
                  className="d-flex justify-content-end mt-4"
                  style={{ position: "relative", zIndex: 3 }}
                >
                  <audio
                    style={{ width: 150, opacity: "50%" }}
                    loop
                    autoPlay
                    controls
                    type="audio/mpeg"
                    src={require("../asset/audio-konkoin.mp3")}
                  ></audio>
                </div>
              ) : (
                ""
              )}
              <Countdown width={width} change={change} setChange={setChange} />
              <Banner width={width} change={change} setChange={setChange} />
            </div> */}
          </div>
          <a href="#presale">
            <CarouselBanner dataImage={dataImage} width={width} />
          </a>
          <div className="container mt-4" style={{ borderRadius: 25 }}>
            <div style={{ position: "relative" }}>
              <video
                // autoPlay
                loop
                // muted

                controls
                width={"100%"}
                src={
                  dataImage
                    ? dataVideo[0].filename
                    : require("../asset/New3.mp4")
                }
                // src="https://drive.google.com/file/d/1efwfbwQKGvcP7ydjkTG1cePQMJzEhGy-/preview"
                style={{ zIndex: -2, marginTop: 0, borderRadius: 25 }}
              ></video>
            </div>
          </div>
          {/* <iframe src="https://drive.google.com/file/d/1efwfbwQKGvcP7ydjkTG1cePQMJzEhGy-/preview" width="640" height="480" allow="autoplay"></iframe> */}

          <div id="about">
            <Konkoin width={width} />
          </div>
          <div id="presale">
            <Bento width={width} />
            <div
              className="text-center  text-white"
              style={{
                fontSize: width <= 540 ? 35 : 60,
                fontWeight: 400,
                marginTop: 150,
              }}
            >
              Video Tutorial
            </div>
            <CarouselVideo width={width} />
          </div>
          <div id="roadmap">
            <Roadmap width={width} />
          </div>

          <div
            style={{
              backgroundImage: `linear-gradient(to bottom, rgba(37,37,37,0) , #080808),url(${imgBackgroundEarth})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: width <= 540 ? "top" : "",
              height: 1200,
            }}
          >
            <Takeonomic width={width} />
          </div>

          <News width={width} />

          <MediaPartner width={width} />

          <div
            className="container"
            style={{ marginTop: 100, paddingBottom: width <= 540 ? 0 : 150 }}
          ></div>

          <div
            className="bg-koni-danger"
            style={{ height: 80, marginBottom: width <= 992 ? 78 : 0 }}
          >
            <div
              className={
                width <= 540
                  ? "container text-center h-100"
                  : "container d-flex align-items-center h-100 justify-content-between"
              }
            >
              <img
                src={imgLogoKonkoin}
                className="img-fluid"
                style={{ width: 200 }}
                alt=""
              />
              <div className="text-white">
                Konkoin © 2024. All Right reserved
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {width <= 992 ? <BottomNavbar /> : ""}
    </>
  )
}
