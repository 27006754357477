import React from "react"
import { useNavigate } from "react-router-dom"
import VerificationProcessImg from "../asset/failed.png"
function FailedNotification() {
  const navigate = useNavigate()
  return (
    <>
      <div
        className="text-roboto d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh", backgroundColor: "#FFFFFF" }}
      >
        <div className="d-flex">
          <div>
            <div className=" text-center">
              <img
                src={VerificationProcessImg}
                alt=""
                className="img-fluid mx-auto"
                style={{ width: 150 }}
              />
              <p className="font-24 fw-bold mt-5">
                Your Delete Account is Failed
              </p>
              <p className="font-14 mb-1">
                Sorry, your account not deleted
              </p>
              <p className="font-14 mb-1">
                please click the button below to login
              </p>
              <p className="font-14"> your account on KONKOIN</p>
            </div>

            {/* button */}
            <div className="d-grid col-6 offset-3">
              
              <a
                
                className="btn text-white mt-3"
                style={{ backgroundColor: "#1B6AEA", height: "41px" }}
                href="konkoin://login"
              >
                KONKOIN
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FailedNotification
