export default function TNCID() { 
    return   <>
    <div className="d-flex justify-content-center mt-4">
      <div className="font-30 fw-bold mb-5">
        SYARAT DAN KETENTUAN APLIKASI MOBILE KONKOIN
      </div>
    </div>
    <div className="mt-4">
      <ul style={{ listStyleType: "decimal" }}>
        <li className="fw-bold mt-">
          Pendahuluan
          <p className="fw-normal mt-2">
            Selamat datang di aplikasi mobile KONKOIN ("Aplikasi").
            Dengan menggunakan Aplikasi kami, Anda setuju untuk mematuhi
            dan terikat oleh syarat dan ketentuan berikut ini
            ("Syarat"). Harap tinjau Syarat ini dengan seksama sebelum
            menggunakan Aplikasi.
          </p>
        </li>
        <li className="fw-bold mt-4">
          Penerimaan Syarat
          <p className="fw-normal mt-2">
            Dengan mengunduh, menginstal, dan/atau menggunakan Aplikasi,
            Anda setuju untuk terikat oleh Syarat ini. Jika Anda tidak
            setuju dengan Syarat ini, jangan gunakan Aplikasi.
          </p>
        </li>
        <li className="fw-bold mt-4">
          Kelayakan
          <p className="fw-normal mt-2">
            Anda harus berusia setidaknya 15 tahun untuk menggunakan
            Aplikasi. Dengan menggunakan Aplikasi, Anda menyatakan dan
            menjamin bahwa Anda berusia setidaknya 15 tahun.
          </p>
        </li>
        <li className="fw-bold mt-4">
          Pendaftaran Akun
          <ul
            className="fw-normal"
            style={{ listStyleType: "lower-alpha" }}
          >
            <li className="mt-2">
              Untuk mengakses fitur tertentu dari Aplikasi, Anda mungkin
              perlu mendaftar untuk sebuah akun ("Akun").
            </li>
            <li className="mt-2">
              Anda setuju untuk memberikan informasi yang akurat,
              terbaru, dan lengkap selama proses pendaftaran dan untuk
              memperbarui informasi tersebut agar tetap akurat, terbaru,
              dan lengkap.
            </li>
            <li className="mt-2">
              Anda bertanggung jawab untuk menjaga kerahasiaan kata
              sandi Akun Anda dan untuk semua aktivitas yang terjadi di
              bawah Akun Anda.
            </li>
          </ul>
        </li>
        <li className="fw-bold mt-4">
          Penggunaan Aplikasi
          <ul
            className="fw-normal"
            style={{ listStyleType: "lower-alpha" }}
          >
            <li className="mt-2">
              Aplikasi memungkinkan Anda untuk mengelola dan
              memperdagangkan cryptocurrency.
            </li>
            <li className="mt-2">
              Anda setuju untuk menggunakan Aplikasi hanya untuk tujuan
              yang sah dan sesuai dengan Syarat ini.
            </li>
          </ul>
        </li>
        <li className="fw-bold mt-4">
          Privasi
          <p className="mt-2 fw-normal">
            Penggunaan Anda atas Aplikasi juga diatur oleh Kebijakan
            Privasi kami.
          </p>
        </li>
        <li className="fw-bold mt-4">
          Kekayaan Intelektual
          <ul
            className="fw-normal"
            style={{ listStyleType: "lower-alpha" }}
          >
            <li className="mt-2">
              Semua konten di Aplikasi, termasuk teks, grafik, logo, dan
              perangkat lunak, adalah milik KONKOIN atau pemberi
              lisensinya dan dilindungi oleh undang-undang hak cipta dan
              kekayaan intelektual lainnya.
            </li>
            <li className="mt-2">
              Anda tidak boleh menggunakan, mereproduksi,
              mendistribusikan, atau membuat karya turunan berdasarkan
              konten ini tanpa izin tertulis dari kami.
            </li>
          </ul>
        </li>
        <li className="fw-bold mt-4">
          Perilaku Pengguna
          <ul
            className="fw-normal"
            style={{ listStyleType: "lower-alpha" }}
          >
            <li className="mt-2">
              Anda setuju untuk tidak menggunakan Aplikasi dengan cara
              apa pun yang dapat merusak, menonaktifkan, membebani, atau
              mengganggu server atau jaringan kami.
            </li>
            <li className="mt-2">
              Anda setuju untuk tidak mencoba mendapatkan akses tidak
              sah ke bagian mana pun dari Aplikasi atau sistem atau
              jaringan lain yang terhubung ke Aplikasi.
            </li>
          </ul>
        </li>
        <li className="fw-bold mt-4">
          Transaksi
          <ul
            className="fw-normal"
            style={{ listStyleType: "lower-alpha" }}
          >
            <li className="mt-2">
              Semua transaksi yang dilakukan melalui Aplikasi bersifat
              final dan tidak dapat dibatalkan.
            </li>
            <li className="mt-2">
              KONKOIN tidak bertanggung jawab atas kehilangan atau
              kerusakan yang diakibatkan oleh transaksi Anda.
            </li>
          </ul>
        </li>
        <li className="fw-bold mt-4">
          Biaya
          <p className="fw-normal mt-2">
            KONKOIN dapat mengenakan biaya untuk layanan tertentu yang
            disediakan melalui Aplikasi. Biaya yang berlaku akan
            diungkapkan kepada Anda sebelum Anda menggunakan layanan
            tersebut.
          </p>
        </li>
        <li className="fw-bold mt-4">
          Penafian
          <ul
            className="fw-normal"
            style={{ listStyleType: "lower-alpha" }}
          >
            <li className="mt-2">
              Aplikasi disediakan "sebagaimana adanya" dan "sebagaimana
              tersedia" tanpa jaminan apa pun.
            </li>
            <li className="mt-2">
              KONKOIN tidak menjamin bahwa Aplikasi akan bebas dari
              kesalahan atau tidak terputus.
            </li>
          </ul>
        </li>
        <li className="fw-bold mt-4">
          Batasan Tanggung Jawab
          <p className="fw-normal mt-2">
            Sejauh yang diizinkan oleh hukum, KONKOIN tidak akan
            bertanggung jawab atas kerusakan tidak langsung, insidental,
            khusus, konsekuensial, atau hukuman apa pun, atau kehilangan
            keuntungan atau pendapatan apa pun, baik yang terjadi secara
            langsung atau tidak langsung, atau kehilangan data,
            penggunaan, goodwill, atau kerugian tidak berwujud lainnya,
            yang diakibatkan oleh (i) penggunaan Anda atau
            ketidakmampuan untuk menggunakan Aplikasi; (ii) setiap akses
            tidak sah ke atau penggunaan server kami dan/atau informasi
            pribadi apa pun yang disimpan di dalamnya; (iii) bug, virus,
            trojan horse, atau sejenisnya yang dapat ditransmisikan ke
            atau melalui Aplikasi oleh pihak ketiga mana pun; atau (iv)
            kesalahan atau kelalaian dalam konten apa pun atau untuk
            kehilangan atau kerusakan apa pun yang terjadi sebagai
            akibat dari penggunaan Anda atas konten apa pun yang
            diposting, dikirimkan melalui email, ditransmisikan, atau
            sebaliknya tersedia melalui Aplikasi.
          </p>
        </li>
        <li className="fw-bold mt-4">
          Ganti Rugi
          <p className="fw-normal mt-2">
            Anda setuju untuk mengganti kerugian dan menjaga KONKOIN,
            afiliasinya, petugas, agen, dan karyawannya dari setiap
            klaim, tuntutan, kerusakan, atau kerugian, termasuk biaya
            pengacara yang wajar, yang timbul dari atau terkait dengan
            penggunaan Anda atas Aplikasi atau pelanggaran Syarat ini.
          </p>
        </li>
        <li className="fw-bold mt-4">
          Hukum yang Berlaku
          <p className="fw-normal mt-2">
            Syarat ini dan penggunaan Anda atas Aplikasi akan diatur
            oleh dan ditafsirkan sesuai dengan hukum yurisdiksi di mana
            KONKOIN berada, tanpa memperhatikan prinsip konflik
            hukumnya.
          </p>
        </li>
        <li className="fw-bold mt-4">
          Perubahan Syarat
          <p className="fw-normal mt-2">
            KONKOIN berhak untuk mengubah Syarat ini kapan saja.
            Perubahan apa pun akan berlaku segera setelah diposting di
            Aplikasi. Penggunaan Anda yang terus berlanjut atas Aplikasi
            setelah perubahan tersebut merupakan penerimaan Anda
            terhadap Syarat yang baru.
          </p>
        </li>
        <li className="fw-bold mt-4">
          Pengakhiran
          <p className="fw-normal mt-2">
            KONKOIN dapat mengakhiri atau menangguhkan akses Anda ke
            Aplikasi kapan saja, tanpa pemberitahuan atau tanggung jawab
            sebelumnya, dengan alasan apa pun, termasuk jika Anda
            melanggar salah satu dari Ketentuan ini.
          </p>
        </li>
        <li className="fw-bold mt-4">
          Hubungi Kami
          <p className="fw-normal mt-2">
            Jika Anda memiliki pertanyaan tentang Ketentuan ini, silakan
            hubungi kami di{" "}
            <a href="mailto:konkoindigital@gmail.com">
              konkoindigital@gmail.com
            </a>
          </p>
        </li>
      </ul>
    </div>
  </>
}