import React, { Component, useEffect, useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { baseurl } from "../config/http"
import axios from "axios"
export default function CarouselVideo({ width }) {
  const [dataVideo, setDataVideo] = useState([])
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: width < 540 ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    borderRadius: 25,
  }
  //   }

  useEffect(() => {
    axios
      .get(baseurl + "/link")
      .then(({ data }) => {
        if (data.data) {
          console.log(data.data)

          let temp = data.data.filter((e) => e.title.split("-")[0] === "video")
          //   e.title.split("-")[0] === "video"
          // })
          setDataVideo(temp)
        }
      })
      .catch((err) => console.log(err))
  }, [])

  console.log(dataVideo)

  return (
    <>
      {dataVideo.length ? (
        <div className="text-white container" style={{ marginTop: 32 }}>
          <div className="slider-container text-white">
            <Slider {...settings}>
              {dataVideo.map((e, i) => {
                return (
                  <div className="d-flex justify-content-center w-100 " key={i}>
                    <iframe
                      style={{ borderRadius: 20 }}
                      src={e.link}
                      className="mx-1"
                      frameborder="0"
                      width={"100%"}
                      height={"100%"}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    ></iframe>
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  )
}
