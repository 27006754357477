import { useEffect, useState } from "react"
import imgTNC from "../asset/logo-konkoin2.svg"
import TNCID from "../component/TNC/tnc-id"
import TNCEN from "../component/TNC/tnc-en"
export default function TNC() {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)
  const [lang, setLang] = useState("id")
  const updateDimensions = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions)
    return () => window.removeEventListener("resize", updateDimensions)
  }, [])
  return (
    <>
      <div style={{ minHeight: "100vh", backgroundColor: "#FFFFFF" }}>
        <div
          className={width < 540 ? "container pt-5" : "container pt-5 w-50"}
          style={{ paddingBottom: 100 }}
        >
          {lang === "id" ? (
            <div className="d-flex">
              <div
                className="text-primary fw-bold font-20 me-3"
                style={{ textDecoration: "underline" }}
              >
                ID
              </div>
              <div
                className="text-secondary fw-bold font-20"
                onClick={() => setLang("en")}
              >
                EN
              </div>
            </div>
          ) : (
            <div className="d-flex">
              <div
                className="text-secondary fw-bold font-20 me-3"
                onClick={() => setLang("id")}
              >
                ID
              </div>
              <div
                className="text-primary fw-bold font-20"
                style={{ textDecoration: "underline" }}
              >
                EN
              </div>
            </div>
          )}

          <div className="d-flex justify-content-center">
            <img src={imgTNC} style={{ width: 100, marginBottom : 25 }} alt="" />
          </div>
          { lang === "id" ?   <TNCID /> : <TNCEN/>}
        
        </div>
      </div>
    </>
  )
}
